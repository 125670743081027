import Router from 'next/router';

export const redirect = (target, res, statusCode = 303) => {
  if (res) {
    if (res.redirect) {
      res.status(statusCode).redirect(target);
    } else {
      res.writeHead(statusCode, { Location: target });
      res.end();
    }
    res.finished = true;
  } else {
    Router.replace(target);
  }
};

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Box from '@material-ui/core/Box';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Toolbar from '@material-ui/core/Toolbar';
import { usePopupState, bindTrigger, bindMenu } from 'material-ui-popup-state/hooks';
import { Link } from '@app/components/Link';
import { LOGOUT_URL } from 'config';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useForm } from 'react-hook-form';
import { SelectLocaleField } from '@app/components/forms/fields/SelectLocaleField';
import { Form } from '@app/components/form/Form';
import { useLocale } from '@app/hooks/useLocale';

export const Header = ({ toggleSidebar }) => {
  const { locale, setLocale } = useLocale();
  const form = useForm({
    defaultValues: {
      locale,
    },
  });

  const selectedLocale = form.watch('locale');
  const popupState = usePopupState({ variant: 'popover', popupId: 'profileDropdown' });

  useEffect(() => {
    setLocale(selectedLocale);
  }, [selectedLocale, setLocale]);

  return (
    <AppBar
      position="fixed"
      sx={{
        zIndex: (theme) => theme.zIndex.drawer + 1,
      }}
    >
      <Toolbar
        sx={{
          '.MuiIconButton-root': {
            fontSize: '1.3rem',
          },
        }}
      >
        <Box
          component={IconButton}
          onClick={toggleSidebar}
          sx={{ marginRight: '10px', color: 'white', display: { xs: 'block', sm: 'none' } }}
        >
          <MenuIcon />
        </Box>

        <Typography variant="h6">
          <Link href="/" sx={{ color: 'inherit' }} underline="none">
            Questo Admin
          </Link>
        </Typography>

        <Box sx={{ ml: 'auto', display: 'flex', alignItems: 'center' }}>
          <Form form={form}>
            <SelectLocaleField
              name="locale"
              sx={{ mr: 2, '.MuiSelect-root': { color: 'white' } }}
            />
          </Form>
          <IconButton color="inherit" {...bindTrigger(popupState)}>
            <FontAwesomeIcon icon="user-circle" />
          </IconButton>

          <Menu {...bindMenu(popupState)} onClick={popupState.close}>
            <MenuItem component={Link} href="/profile">
              My profile
            </MenuItem>
            <MenuItem component={Link} href={LOGOUT_URL}>
              Logout
            </MenuItem>
          </Menu>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

Header.propTypes = {
  toggleSidebar: PropTypes.func.isRequired,
};

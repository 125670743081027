import { gql } from '@apollo/client';
import { withApollo } from './withApollo';
import * as authToken from '@app/lib/authToken';
import { GetServerSideProps, GetServerSidePropsContext, GetServerSidePropsResult } from 'next';
import { WithApolloContext, WithUserContext } from '@app/types';

export const CURRENT_USER_QUERY = gql`
  query user {
    user {
      id
      name
      roles
    }
  }
`;

export const withUser = (
  handler: (
    ctx: WithUserContext<WithApolloContext<GetServerSidePropsContext>>,
  ) => Promise<GetServerSidePropsResult<Record<string, any>>>,
): GetServerSideProps =>
  withApollo(async (ctx: WithUserContext<WithApolloContext<GetServerSidePropsContext>>) => {
    if (authToken.get(ctx)) {
      try {
        const { data, error } = await ctx.apolloClient.query({
          query: CURRENT_USER_QUERY,
        });

        if (!error && data?.user) {
          ctx.user = data.user;
        }
      } catch (err) {
        console.log(err);
      }
    }

    const result: any = (handler && (await handler(ctx))) || {};

    if (result.redirect || result.notFound) {
      return result;
    }

    return {
      ...result,
      props: {
        ...(ctx.user ? { user: ctx.user } : {}),
        ...((result as any)?.props || {}),
      },
    };
  });

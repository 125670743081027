import React from 'react';
import { DEFAULT_LOCALE } from '@app/constants';
import { useLocalStorage } from 'react-use';
import LocaleContext from './contexts/Locale';

export const LocaleProvider = ({ children }) => {
  const [locale, setLocale] = useLocalStorage('locale', DEFAULT_LOCALE);

  return <LocaleContext.Provider value={{ locale, setLocale }}>{children}</LocaleContext.Provider>;
};

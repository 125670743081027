import React, { AnchorHTMLAttributes, ComponentProps, DetailedHTMLProps, forwardRef } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useRouter } from 'next/router';
import NextLink from 'next/link';
import MuiLink from '@material-ui/core/Link';
import { UrlObject } from 'url';

type Url = string | UrlObject;

const NextLinkComposed = forwardRef<
  HTMLAnchorElement,
  Partial<ComponentProps<typeof NextLink>> & {
    to: Url;
    linkAs?: Url;
    href?: string;
    className?: string;
  }
>(
  (
    {
      to,
      linkAs,
      href,
      replace,
      scroll,
      passHref,
      shallow,
      prefetch,
      locale,
      ...other
    }: ComponentProps<typeof NextLink> & { to: Url; linkAs: Url; href: string },
    ref,
  ) => {
    return (
      <NextLink
        href={to}
        prefetch={prefetch}
        as={linkAs}
        replace={replace}
        scroll={scroll}
        shallow={shallow}
        passHref={passHref}
        locale={locale}
      >
        <a ref={ref} {...other} />
      </NextLink>
    );
  },
);

export const Link = React.forwardRef<
  HTMLAnchorElement,
  ComponentProps<typeof MuiLink> & {
    activeClassName?: string;
    as?: Url;
    noLinkStyle?: boolean;
    href: Url;
  }
>(
  (
    {
      activeClassName = 'active',
      as: linkAs,
      className: classNameProps,
      href,
      noLinkStyle = false,
      role,
      ...other
    },
    ref,
  ) => {
    const router = useRouter();
    const pathname = typeof href === 'string' ? href : (href as UrlObject).pathname;
    const className = clsx(classNameProps, {
      [activeClassName]: router.pathname === pathname && activeClassName,
    });

    const isExternal =
      typeof href === 'string' && (href.indexOf('http') === 0 || href.indexOf('mailto:') === 0);

    if (isExternal) {
      if (noLinkStyle) {
        return (
          <a
            className={className}
            href={href}
            ref={ref as any}
            {...(other as DetailedHTMLProps<
              AnchorHTMLAttributes<HTMLAnchorElement>,
              HTMLAnchorElement
            >)}
          />
        );
      }

      return <MuiLink className={className} href={href} ref={ref} {...other} />;
    }

    if (noLinkStyle) {
      return <NextLinkComposed className={className} ref={ref as any} to={href} {...other} />;
    }

    return (
      <MuiLink
        component={NextLinkComposed}
        linkAs={linkAs}
        className={className}
        ref={ref}
        to={href}
        {...other}
      />
    );
  },
);

Link.propTypes = {
  activeClassName: PropTypes.string,
  as: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  className: PropTypes.string,
  href: PropTypes.any,
  noLinkStyle: PropTypes.bool,
  role: PropTypes.string,
};

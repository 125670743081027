import nookies from 'nookies';
import { AUTH_TOKEN_KEY } from '@app/constants';
import { COOKIE_TTL } from '@app/config';

export const set = (ctx, token, remember = false) =>
  nookies.set(ctx, AUTH_TOKEN_KEY, token, {
    path: '/',
    ...(remember ? { maxAge: COOKIE_TTL } : {}),
  });

export const clear = (ctx?) => {
  nookies.destroy(ctx, AUTH_TOKEN_KEY, {
    domain: process.env.COOKIE_DOMAIN,
    path: '/',
  });
};

export const get = (ctx?) => nookies.get(ctx)[AUTH_TOKEN_KEY];

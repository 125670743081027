import React from 'react';
import type { AppProps } from 'next/app';
import Head from 'next/head';
import { SnackbarProvider } from 'notistack';
import { ThemeProvider } from '@material-ui/core/styles';
import { CacheProvider } from '@emotion/react';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ApolloProvider } from '@apollo/client';
import theme from '@app/styles/theme';
import { useApollo } from '@app/lib/apolloClient';
import { User } from '@app/components/User';
import { InternalLayout } from '@app/components/layouts/internal/InternalLayout';
import { cache } from 'styles/cache';
import { library, config } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import AdapterDayjs from '@material-ui/lab/AdapterDayjs';
import LocalizationProvider from '@material-ui/lab/LocalizationProvider';
import '@fortawesome/fontawesome-svg-core/styles.css';
import { initSentry } from '@app/lib/sentry';
import { LocaleProvider } from '@app/components/LocaleProvider';

initSentry();

config.autoAddCss = false;
config.showMissingIcons = true;
library.add(fas as any, far as any);

export default function MyApp({
  Component,
  pageProps,
  err,
}: AppProps & { Component: { Layout?: any }; err: any }) {
  const apolloClient = useApollo(pageProps);

  React.useEffect(() => {
    // Remove the server-side injected CSS.
    const jssStyles = document.querySelector('#jss-server-side');
    if (jssStyles) {
      jssStyles.parentElement.removeChild(jssStyles);
    }
  }, []);

  const Layout = Component.Layout || InternalLayout;

  return (
    <SnackbarProvider maxSnack={3} anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <CacheProvider value={cache}>
          <ApolloProvider client={apolloClient}>
            <Head>
              <title>Questo Admin</title>
              <meta name="viewport" content="initial-scale=1, width=device-width" />
            </Head>
            <ThemeProvider theme={theme}>
              <CssBaseline />
              <LocaleProvider>
                <User initialUser={pageProps.user}>
                  <Layout>
                    <Component {...pageProps} err={err} />
                  </Layout>
                </User>
              </LocaleProvider>
            </ThemeProvider>
          </ApolloProvider>
        </CacheProvider>
      </LocalizationProvider>
    </SnackbarProvider>
  );
}

import { Locale, UserRole } from '@app/enums';
import googleTimezones from 'google-timezones-json';

export const AUTH_TOKEN_KEY = 'authToken';
export const APOLLO_STATE_PROP_NAME = '__APOLLO_STATE__';
export const DATE_FORMAT = 'DD/MM/YYYY';
export const DATE_TIME_FORMAT = 'DD/MM/YYYY HH:mm';
export const UPLOAD_ERROR = 'UPLOAD_ERROR';
export const LOCALE_TO_LANGUAGE: Record<Locale, string> = {
  en: 'English',
  ro: 'Romanian',
  fr: 'French',
  it: 'Italian',
  nl: 'Dutch',
  de: 'German',
  es: 'Spanish',
  pt: 'Portuguese',
  sl: 'Slovene',
};
export const DEFAULT_LOCALE = Locale.en;
export const ALLOWED_ROLES = [UserRole.SuperAdmin, UserRole.Admin, UserRole.CustomerSupport];

export const DEFAULT_LOCATION = {
  lat: 44.43576,
  lng: 26.102097,
};

export const CREATOR_ROLES: UserRole[] = [
  UserRole.Creator,
  UserRole.IndependentCreator,
  UserRole.BusinessCreator,
  UserRole.Writer,
  UserRole.Translator,
];

export const TIMEZONES = googleTimezones;

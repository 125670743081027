import React, { ComponentProps, forwardRef, ReactElement } from 'react';
import ListItem from '@material-ui/core/ListItem';
import { Link } from '@app/components/Link';

export const ListItemLink = forwardRef<
  HTMLDivElement,
  Partial<ComponentProps<typeof ListItem>> & { component?: ReactElement } & ComponentProps<
      typeof Link
    >
>((props, ref) => <ListItem button component={Link} {...props} ref={ref} />);

import { gql, useQuery } from '@apollo/client';
import { ListItemLink } from '@app/components/ListItemLink';
import { Visible } from '@app/components/Visible';
import { UserRole } from '@app/enums';
import { useUser } from '@app/hooks/useUser';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Collapse, Toolbar, useMediaQuery } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import MuiDrawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import MuiListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import RemoveIcon from '@material-ui/icons/Remove';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

const Drawer = (props) => (
  <Box
    component={MuiDrawer}
    sx={{
      boxSizing: 'border-box',
      width: 260,
      flexShrink: 0,
      '.MuiPaper-root': {
        width: 260,
        bgcolor: 'white',
        borderColor: '#ededf7',
      },
    }}
    {...props}
  />
);

const ListItemIcon = (props) => (
  <Box
    sx={{ minWidth: '0 !important', textAlign: 'center', svg: { mr: 2, width: '1rem!important' } }}
    component={MuiListItemIcon}
    {...props}
  />
);

const formatCounter = (nr: number) =>
  typeof nr !== 'number' || Number.isNaN(nr) ? '-' : new Intl.NumberFormat().format(nr);

const SidebarItem = ({
  href,
  icon,
  primaryText,
  secondaryText,
  isSelectedFor,
  subItems,
}: {
  href?: string;
  icon: IconProp;
  primaryText: string;
  secondaryText?: string;
  isSelectedFor?: string | RegExp;
  subItems?: { href: string; label: string; counter?: string }[];
}) => {
  const router = useRouter();
  const isSelected = (pathname) =>
    pathname instanceof RegExp ? pathname.test(router.pathname) : router.pathname === pathname;
  const hasSubItems = Array.isArray(subItems) && subItems.length;
  const [open, setOpen] = useState(hasSubItems && subItems.some((item) => isSelected(item.href)));
  const Wrapper = href ? ListItemLink : ListItem;

  return (
    <>
      <Wrapper
        button
        href={href}
        selected={!hasSubItems && isSelectedFor && isSelected(isSelectedFor)}
        onClick={() => {
          if (hasSubItems) {
            setOpen(!open);
          }
        }}
      >
        <ListItemIcon>
          <FontAwesomeIcon icon={icon} />
        </ListItemIcon>
        <ListItemText primary={primaryText} secondary={secondaryText} />
        {hasSubItems ? open ? <ExpandLess /> : <ExpandMore /> : null}
      </Wrapper>
      {hasSubItems && (
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {subItems.map((item, index) => (
              <ListItemLink
                key={index}
                sx={{ pl: 2 }}
                href={item.href}
                selected={isSelected(item.href)}
              >
                <ListItemIcon>
                  <RemoveIcon />
                </ListItemIcon>
                <ListItemText primary={item.label} secondary={item.counter} />
              </ListItemLink>
            ))}
          </List>
        </Collapse>
      )}
    </>
  );
};

const DrawerContent = () => {
  const { user, isOneOf } = useUser();
  const countersQuery = useQuery(COUNTERS_QUERY, {
    pollInterval: 1000 * 60 * 5,
    ssr: false,
    variables: {
      isAdmin: !!user && isOneOf([UserRole.Admin, UserRole.SuperAdmin]),
    },
  });

  return (
    <Box sx={{ px: 3, py: 2 }}>
      <List component="nav">
        <SidebarItem href="/" isSelectedFor="/" icon="home" primaryText="Dashboard" />

        <Visible toRole={UserRole.Admin}>
          <SidebarItem
            icon="table"
            primaryText="Reports"
            subItems={[
              { href: '/reports/creators-revenue', label: 'Creators revenue' },
              { href: '/reports/customer-retention', label: 'Customer retention' },
              { href: '/reports/sales-per-channel', label: 'Sales / Channel' },
              { href: '/reports/games-played-per-channel', label: 'Games played / Channel' },
              { href: '/reports/sales-per-city', label: 'Sales / City' },
              { href: '/reports/games-played-per-city', label: 'Games played / City' },
              { href: '/reports/user-invites', label: 'User invites' },
              {
                href: '/reports/bookings',
                label: 'Bookings',
                counter: formatCounter(countersQuery.data?.countPurchases),
              },
            ]}
          />
        </Visible>

        <SidebarItem
          href="/quests"
          isSelectedFor={/^\/quests/}
          icon="search-location"
          primaryText="Quests"
          secondaryText={formatCounter(countersQuery.data?.countQuests)}
        />

        <Visible toRole={UserRole.Admin}>
          <SidebarItem
            icon="calendar-alt"
            primaryText="Events"
            subItems={[
              {
                href: '/quest-events',
                label: 'Events',
                counter: formatCounter(countersQuery.data?.countQuestEvents),
              },
              {
                href: '/quest-event-spots',
                label: 'Reserved spots',
                counter: formatCounter(countersQuery.data?.countQuestEventSpots),
              },
            ]}
          />
        </Visible>

        <SidebarItem
          href="/challenges"
          isSelectedFor={/^\/challenges/}
          icon="hourglass-half"
          primaryText="Challenges"
          secondaryText={formatCounter(countersQuery.data?.countChallenges)}
        />

        <SidebarItem
          href="/quest-reviews"
          isSelectedFor={/^\/quest-reviews/}
          icon="comment"
          primaryText="Reviews"
          secondaryText={formatCounter(countersQuery.data?.countQuestReviews)}
        />

        <Visible toRole={UserRole.Admin}>
          <SidebarItem
            href="/quest-invites"
            isSelectedFor={/^\/quest-invites/}
            icon="envelope-open"
            primaryText="Quest invites"
            secondaryText={formatCounter(countersQuery.data?.countQuestInvites)}
          />
        </Visible>

        <SidebarItem
          href="/user-invites"
          isSelectedFor={/^\/user-invites/}
          icon="user-plus"
          primaryText="User invites"
          secondaryText={formatCounter(countersQuery.data?.countUserInvites)}
        />

        <SidebarItem
          href="/users"
          isSelectedFor={/^\/users/}
          icon="users"
          primaryText="Users"
          secondaryText={formatCounter(countersQuery.data?.countUsers)}
        />

        <SidebarItem
          href="/reseller-applications"
          isSelectedFor={/^\/reseller-applications/}
          icon="money-bill-wave"
          primaryText="Reseller Applications"
          secondaryText={formatCounter(countersQuery.data?.countResellerApplications)}
        />

        {/* <Visible toRole={UserRole.Admin}>
          <ListItemLink
            href="/independent-creators"
            selected={isSelected(/^\/independent-creators/)}
          >
            <ListItemIcon>
              <FontAwesomeIcon icon="user-graduate" />
            </ListItemIcon>
            <ListItemText
              primary="Independent creators"
              secondary={formatCounter(countersQuery.data?.countIndependentCreators)}
            />
          </ListItemLink>

          <ListItemLink href="/business-creators" selected={isSelected(/^\/business-creators/)}>
            <ListItemIcon>
              <FontAwesomeIcon icon="user-tie" />
            </ListItemIcon>
            <ListItemText
              primary="Business creators"
              secondary={formatCounter(countersQuery.data?.countBusinessCreators)}
            />
          </ListItemLink>
        </Visible> */}

        <SidebarItem
          href="/games"
          isSelectedFor={/^\/games/}
          icon="mobile-alt"
          primaryText="Games"
          secondaryText={formatCounter(countersQuery.data?.countGames)}
        />

        <Visible toRole={UserRole.Admin}>
          <SidebarItem
            href="/game-pictures"
            isSelectedFor={/^\/game-pictures/}
            icon="images"
            primaryText="Game Pictures"
            secondaryText={formatCounter(countersQuery.data?.countGamePictures)}
          />
        </Visible>

        <SidebarItem
          href="/game-plays"
          isSelectedFor={/^\/game-plays/}
          icon="gamepad"
          primaryText="Game plays"
          secondaryText={formatCounter(countersQuery.data?.countGamePlays)}
        />

        <Visible toRole={UserRole.Admin}>
          <SidebarItem
            href="/payments"
            isSelectedFor={/^\/payments/}
            icon="credit-card"
            primaryText="Payments"
            secondaryText={formatCounter(countersQuery.data?.countPayments)}
          />
        </Visible>

        <Visible toRole={UserRole.Admin}>
          <SidebarItem
            icon="gem"
            primaryText="Gems"
            subItems={[
              {
                href: '/gems-packages',
                label: 'Packages',
                counter: formatCounter(countersQuery.data?.countGemsPackages),
              },
              {
                href: '/gems-transactions',
                label: 'Transactions',
                counter: formatCounter(countersQuery.data?.countGemsPackagePayments),
              },
            ]}
          />
        </Visible>

        <SidebarItem
          href="/partners"
          isSelectedFor={/^\/partners/}
          icon="handshake"
          primaryText="Partners"
          secondaryText={formatCounter(countersQuery.data?.countPartners)}
        />

        <Visible toRole={UserRole.Admin}>
          <SidebarItem
            href="/unlock-tokens"
            isSelectedFor={/^\/unlock-tokens/}
            icon="lock-open"
            primaryText="Free Tokens"
            secondaryText={formatCounter(countersQuery.data?.countUnlockTokens)}
          />

          <SidebarItem
            href="/cities"
            isSelectedFor={/^\/cities/}
            icon="city"
            primaryText="Cities"
            secondaryText={formatCounter(countersQuery.data?.countCities)}
          />

          <SidebarItem
            href="/countries"
            isSelectedFor={/^\/countries/}
            icon="flag"
            primaryText="Countries"
            secondaryText={formatCounter(countersQuery.data?.countCountries)}
          />

          <SidebarItem
            href="/locations"
            isSelectedFor={/^\/locations/}
            icon="map-marked-alt"
            primaryText="Locations"
            secondaryText={formatCounter(countersQuery.data?.countLocations)}
          />
        </Visible>

        <Visible toRole={UserRole.Admin}>
          <SidebarItem
            href="/quest-tags"
            isSelectedFor={/^\/quest-tags/}
            icon="tags"
            primaryText="Quest Tags"
            secondaryText={formatCounter(countersQuery.data?.countQuestTags)}
          />

          <SidebarItem
            href="/quest-downloads"
            isSelectedFor={/^\/quest-downloads/}
            icon="download"
            primaryText="Quest downloads"
            secondaryText={formatCounter(countersQuery.data?.countQuestDownloads)}
          />
        </Visible>

        <Visible toRole={UserRole.Admin}>
          <SidebarItem
            href="/webhook-bookings"
            isSelectedFor={/^\/webhook-bookings/}
            icon="circle-notch"
            primaryText="Webhook bookings"
            secondaryText={formatCounter(countersQuery.data?.countWebhookBookings)}
          />
        </Visible>

        <Visible toRole={UserRole.Admin}>
          <SidebarItem
            href="/pois"
            isSelectedFor={/^\/pois/}
            icon="landmark"
            primaryText="Points of interest"
            secondaryText={formatCounter(countersQuery.data?.countPointsOfInterest)}
          />
        </Visible>

        <Visible toRole={UserRole.Admin}>
          <SidebarItem
            href="/attractions"
            isSelectedFor={/^\/attractions/}
            icon="landmark"
            primaryText="Attractions"
            secondaryText={formatCounter(countersQuery.data?.countAttractions)}
          />
        </Visible>
      </List>
    </Box>
  );
};

export const Sidebar = ({ isSidebarOpen, toggleSidebar }) => {
  const isHidden = useMediaQuery((theme: any) => theme.breakpoints.up('sm'));

  return (
    <>
      {!isHidden && (
        <Box>
          <Drawer
            ModalProps={{
              keepMounted: true,
            }}
            variant="temporary"
            anchor="left"
            open={isSidebarOpen}
            onClose={toggleSidebar}
          >
            <DrawerContent />
          </Drawer>
        </Box>
      )}

      <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
        <Drawer variant="permanent" open>
          <Toolbar />
          <DrawerContent />
        </Drawer>
      </Box>
    </>
  );
};

Sidebar.propTypes = {
  isSidebarOpen: PropTypes.bool.isRequired,
  toggleSidebar: PropTypes.func.isRequired,
};

const COUNTERS_QUERY = gql`
  query counters($isAdmin: Boolean!) {
    countQuests
    countQuestEvents @include(if: $isAdmin)
    countQuestEventSpots @include(if: $isAdmin)
    countChallenges
    countCities @include(if: $isAdmin)
    countUsers
    countCountries @include(if: $isAdmin)
    countLocations @include(if: $isAdmin)
    countGames
    countGamePictures: countGames(filters: { pictureId: { exists: true } }) @include(if: $isAdmin)
    countGamePlays
    countPayments @include(if: $isAdmin)
    countWebhookBookings @include(if: $isAdmin)
    countQuestReviews
    countQuestInvites @include(if: $isAdmin)
    countPartners
    countUnlockTokens @include(if: $isAdmin)
    countPurchases @include(if: $isAdmin)
    countIndependentCreators: countUsers(filters: { roles: { eq: Creator } }) @include(if: $isAdmin)
    countBusinessCreators: countUsers(filters: { roles: { eq: BusinessCreator } })
      @include(if: $isAdmin)
    countUserInvites
    countQuestDownloads: countEvents(filters: { type: { eq: QuestDownload } })
      @include(if: $isAdmin)
    countAttractions: countAttractions @include(if: $isAdmin)
    countResellerApplications @include(if: $isAdmin)
    countQuestTags @include(if: $isAdmin)
    countGemsPackages @include(if: $isAdmin)
    countGemsPackagePayments @include(if: $isAdmin)
    countPointsOfInterest: countPointsOfInterest @include(if: $isAdmin)
  }
`;

/* eslint-disable no-param-reassign */
import { useMemo } from 'react';
import {
  ApolloClient,
  InMemoryCache,
  from,
  ServerError,
  NormalizedCacheObject,
} from '@apollo/client';
import merge from 'deepmerge';
import isEqual from 'lodash/isEqual';
import { onError } from 'apollo-link-error';
import { setContext } from '@apollo/client/link/context';
import { createUploadLink } from 'apollo-upload-client';
import { GRAPHQL_URI, LOGOUT_URL } from '../config';
import { redirect } from './redirect';
import * as authToken from './authToken';
import { APOLLO_STATE_PROP_NAME } from '@app/constants';
import { buildAxiosFetch } from '@lifeomic/axios-fetch';
import axios from 'axios';
import apolloLogger from 'apollo-link-logger';
import { compact } from 'lodash';

const isServer = typeof window === 'undefined';
const isBrowser = !isServer;
let apolloClient: ApolloClient<NormalizedCacheObject>;
const isDevelopment = process.env.NODE_ENV === 'development';

function createApolloClient(ctx) {
  const errorLink = onError((error) => {
    const { networkError, graphQLErrors } = error;

    if (graphQLErrors) {
      graphQLErrors.map(({ message, path }) =>
        console.log(`[GraphQL error]: Message: ${message}, Path: ${JSON.stringify(path, null, 2)}`),
      );
    }

    if (networkError) {
      console.log(`[Network error]: ${networkError}`);
    }

    if (
      (networkError && (networkError as ServerError).statusCode === 401) ||
      (Array.isArray(graphQLErrors) && graphQLErrors[0].extensions.code === 'UNAUTHENTICATED')
    ) {
      redirect(LOGOUT_URL, ctx?.res);
    }
  });

  const authLink = setContext((_, { headers }) => {
    const token = authToken.get(ctx);

    return {
      headers: {
        ...headers,
        ...(token ? { Authorization: `Bearer ${token}` } : {}),
      },
    };
  });

  const uploadLink = createUploadLink({
    uri: GRAPHQL_URI,
    fetch: buildAxiosFetch(axios, (config, input, init) => ({
      ...config,
      onUploadProgress: init.onUploadProgress,
    })),
  });

  return new ApolloClient({
    connectToDevTools: isBrowser,
    ssrMode: isServer,
    link: from(
      compact([isDevelopment && isBrowser ? apolloLogger : null, errorLink, authLink, uploadLink]),
    ),
    cache: new InMemoryCache(),
  });
}

export function initializeApollo(initialState = null, ctx?) {
  const client = apolloClient ?? createApolloClient(ctx);

  if (initialState) {
    const existingCache = client.extract();

    const data = merge(initialState, existingCache, {
      arrayMerge: (destinationArray, sourceArray) => [
        ...sourceArray,
        ...destinationArray.filter((d) => sourceArray.every((s) => !isEqual(d, s))),
      ],
    });

    client.cache.restore(data);
  }

  if (isServer) {
    return client;
  }

  if (!apolloClient) {
    apolloClient = client;
  }

  return client;
}

export function addApolloState(client, pageProps) {
  if (pageProps?.props) {
    pageProps.props[APOLLO_STATE_PROP_NAME] = client.cache.extract();
  }
  return pageProps;
}

export function useApollo(pageProps) {
  const state = pageProps[APOLLO_STATE_PROP_NAME];
  const store = useMemo(() => initializeApollo(state), [state]);
  return store;
}

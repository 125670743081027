import React, { ReactNode, useEffect, useRef, useState } from 'react';
import Box from '@material-ui/core/Box';
import { Toolbar } from '@material-ui/core';
import { useRouter } from 'next/router';
import { wordWrap } from 'polished';
import { Header } from './Header';
import { Sidebar } from './Sidebar';

export const InternalLayout = ({ children }: { children: ReactNode }) => {
  const router = useRouter();
  const contentRef = useRef<HTMLDivElement>();
  const [isSidebarOpen, setSidebarOpen] = useState(false);
  const toggleSidebar = () => setSidebarOpen(!isSidebarOpen);

  useEffect(() => {
    const handleRouteChange = () => {
      setSidebarOpen(false);

      if (contentRef.current) {
        contentRef.current.scrollTop = 0;
      }
    };

    router.events.on('routeChangeComplete', handleRouteChange);

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, []);

  return (
    <>
      <Header toggleSidebar={toggleSidebar} />

      <Box sx={{ display: 'flex', height: '100vh', minHeight: 600 }}>
        <Sidebar isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />

        <Box
          ref={contentRef}
          sx={{
            display: 'flex',
            flexGrow: 1,
            flexDirection: 'column',
            p: 3,
            ...wordWrap('break-word'),
            wordBreak: 'break-word',
            hyphens: 'auto',
            overflow: 'scroll',
          }}
        >
          <Toolbar />
          {children}
        </Box>
      </Box>
    </>
  );
};

import { Locale } from '@app/enums';
import upperFirst from 'lodash/upperFirst';
import words from 'lodash/words';
import slugify from '@sindresorhus/slugify';
import last from 'lodash/last';
import { DATE_TIME_FORMAT, DEFAULT_LOCALE } from '@app/constants';
import { WithTranslations } from '@app/types';
import isEmpty from 'lodash/isEmpty';
import dayjs from 'dayjs';
import dayjsUTC from 'dayjs/plugin/utc';
import dayjsTimezone from 'dayjs/plugin/timezone';

dayjs.extend(dayjsUTC);
dayjs.extend(dayjsTimezone);

export const priceFormatter = new Intl.NumberFormat('en-US', {
  minimumFractionDigits: 0,
  maximumFractionDigits: 2,
});

const LOCALE_TO_FLAG_MAP = {
  en: 'US',
};

export const localeToFlag = (locale: Locale): string =>
  (LOCALE_TO_FLAG_MAP[locale] ?? locale)
    .toUpperCase()
    .replace(/./g, (char) => String.fromCodePoint(char.charCodeAt(0) + 127397));

export const titleize = (str: string) => words(str).map(upperFirst).join(' ');

export const toSlug = (value) =>
  `${slugify(value || '')}${['-', ' '].includes(last(value)) ? '-' : ''}`;

export const toDateTime = (value: number | string | Date): string =>
  dayjs(value).format(DATE_TIME_FORMAT);

export const getTranslated = <T extends WithTranslations>(
  obj: T,
  property: string,
  locale: Locale,
  withFallback = true,
): string => {
  const translation = (obj.translations || []).find((i) => i.locale === locale);

  if (translation) {
    return translation[property];
  }

  if (withFallback && locale !== DEFAULT_LOCALE) {
    return getTranslated(obj, property, DEFAULT_LOCALE, false);
  }
};

export const getAvailableLocales = <
  T extends WithTranslations,
  K extends keyof T['translations'][number]
>(
  obj: T,
  property: K,
): Locale[] =>
  (obj.translations || [])
    .filter((translation) => !isEmpty(translation[property as string]))
    .map((i) => i.locale);

export const getAnyTranslated = <T extends WithTranslations>(
  obj: T,
  property: string,
  locale: Locale = DEFAULT_LOCALE,
  withFallback = true,
): string => {
  return (
    getTranslated(obj, property, locale, withFallback) ||
    getTranslated(obj, property, getAvailableLocales(obj, property)[0])
  );
};

export const valueAsNumber = (value) => {
  if (!value.trim()) {
    return null;
  }

  return /^-?\d*\.?\d+$/.test(value) ? Number(value) : value;
};

export const valueAsInt = (value) => {
  const nr = parseInt(value, 10);
  return Number.isNaN(nr) ? value : nr;
};

export const guessTimezone = () => dayjs.tz.guess();

export const shiftPickerDateToTimezoneDate = (pickerDate, timezone = 'UTC') =>
  dayjs(pickerDate)
    .add(dayjs().utcOffset(), 'm')
    .subtract(dayjs().tz(timezone).utcOffset(), 'm')
    .toDate();

export const shiftTimezoneDateToPickerDate = (tzDate, timezone = 'UTC') =>
  dayjs(tzDate)
    .subtract(dayjs().utcOffset(), 'm')
    .add(dayjs().tz(timezone).utcOffset(), 'm')
    .toDate();

export const omitDeep = (
  input: Record<string, any>,
  excludes: Array<number | string>,
): Record<string, any> => {
  return Object.entries(input).reduce((nextInput, [key, value]) => {
    const shouldExclude = excludes.includes(key);
    if (shouldExclude) return nextInput;

    if (Array.isArray(value)) {
      const arrValue = value;
      const nextValue = arrValue.map((arrItem) => {
        if (typeof arrItem === 'object') {
          return omitDeep(arrItem, excludes);
        }
        return arrItem;
      });
      nextInput[key] = nextValue;
      return nextInput;
    } else if (typeof value === 'object' && value !== null) {
      nextInput[key] = omitDeep(value, excludes);
      return nextInput;
    }

    nextInput[key] = value;

    return nextInput;
  }, {});
};

export const stripHtml = (str: string) => (str || '').replace(/(<([^>]+)>)/gi, '');

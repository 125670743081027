import React from 'react';
import PropTypes from 'prop-types';
import { useQuery, useMutation, useApolloClient, gql } from '@apollo/client';
import { useRouter } from 'next/router';
import nookies from 'nookies';
import * as authToken from '@app/lib/authToken';
import UserContext from './contexts/User';
import { CURRENT_USER_QUERY } from '@app/propsDecorators/withUser';
import { UserRole } from '@app/enums';
import { ALLOWED_ROLES } from '@app/constants';
import { intersection } from 'lodash';

export const User = ({ children, initialUser = null }) => {
  const router = useRouter();
  const apolloClient = useApolloClient();
  const { data, loading, refetch } = useQuery(CURRENT_USER_QUERY, {
    pollInterval: 10 * 60 * 1000,
  });
  const [loginMutation] = useMutation(LOGIN_MUTATION);

  const user = loading || !data ? initialUser : data.user;

  const resetStore = async () => {
    await apolloClient.clearStore();

    const reset = async () => {
      router.events.off('routeChangeComplete', reset);
      try {
        await apolloClient.resetStore();
      } catch (err) {
        console.error(err);
      }
    };

    router.events.on('routeChangeComplete', reset);
  };

  const login = async (username, password, redirectTo, remember = false) => {
    const result = await loginMutation({
      variables: {
        username,
        password,
      },
    });

    if (
      !result.data.login ||
      !intersection(ALLOWED_ROLES, result.data.login?.user?.roles || []).length
    ) {
      throw new Error('Invalid credentials!');
    }

    authToken.set(null, result.data.login.token, remember);
    nookies.destroy(null, 'redirectTo', {
      path: '/',
    });

    await resetStore();

    router.push(redirectTo || '/');
  };

  const logout = async () => {
    authToken.clear();

    await resetStore();

    router.push('/');
  };

  const is = (role: UserRole) => user && (user.roles || []).includes(role);

  const isOneOf = (roles: UserRole[]) => !!roles.find((role) => is(role));

  const isSuperAdmin = () => is(UserRole.SuperAdmin);

  const isAdmin = () => isSuperAdmin() || is(UserRole.Admin);

  return (
    <UserContext.Provider
      value={{ user, login, logout, is, isOneOf, isAdmin, isSuperAdmin, reload: refetch }}
    >
      {children}
    </UserContext.Provider>
  );
};

User.propTypes = {
  children: PropTypes.node.isRequired,
  initialUser: PropTypes.object,
};

const LOGIN_MUTATION = gql`
  mutation login($username: String!, $password: String!) {
    login(username: $username, password: $password) {
      token
      user {
        roles
      }
    }
  }
`;

export enum Locale {
  en = 'en',
  ro = 'ro',
  fr = 'fr',
  it = 'it',
  nl = 'nl',
  de = 'de',
  es = 'es',
  pt = 'pt',
  sl = 'sl',
}

export enum QuestStatus {
  Active = 'Active',
  Inactive = 'Inactive',
  Review = 'Review',
}

export enum QuestPriceType {
  Specific = 'Specific',
  Generic = 'Generic',
  Popular = 'Popular',
}

export enum QuestReviewState {
  Active = 'Active',
  New = 'New',
  DraftSent = 'DraftSent',
  DraftApproved = 'DraftApproved',
  RouteDraftSent = 'RouteDraftSent',
  RouteDraftApproved = 'RouteDraftApproved',
  IntermediaryDraftSent = 'IntermediaryDraftSent',
  IntermediaryDraftApproved = 'IntermediaryDraftApproved',
}

export enum QuestState {
  Proposal = 'Proposal',
  FirstDraft = 'FirstDraft',
  Basis = 'Basis',
  CompleteDraft = 'CompleteDraft',
  FinalTouches = 'FinalTouches',
  Testing = 'Testing',
  Published = 'Published',
  Feedback = 'Feedback',
}

export enum ApprovalState {
  Submitted = 'Submitted',
  Approved = 'Approved',
  Rejected = 'Rejected',
}

export enum QuestType {
  Standard = 'Standard',
  Event = 'Event',
}

export enum QuestTheme {
  Standard = 'Standard',
  Haunted = 'Haunted',
}

export enum QuestEventTheme {
  Dark = 'Dark',
  Light = 'Light',
}

export enum UserRole {
  SuperAdmin = 'SuperAdmin',
  User = 'User',
  Admin = 'Admin',
  Editor = 'Editor',
  Tester = 'Tester',
  Creator = 'Creator',
  IndependentCreator = 'IndependentCreator',
  BusinessCreator = 'BusinessCreator',
  Writer = 'Writer',
  Translator = 'Translator',
  TourOperator = 'TourOperator',
  CustomerSupport = 'CustomerSupport',
}

export enum Currency {
  RON = 'RON',
  USD = 'USD',
  EUR = 'EUR',
  GBP = 'GBP',
  AUD = 'AUD',
}

export enum WebhookBookingSource {
  FareHarbor = 'FareHarbor',
  Rezdy = 'Rezdy',
  Stripe = 'Stripe',
  Bokun = 'Bokun',
}

export enum TokenCategory {
  Coupon = 'Coupon',
  Unlock = 'Unlock',
}

export enum TokenDiscountType {
  General = 'General',
  Tour = 'Tour',
  City = 'City',
}

export enum PaymentMethod {
  Card = 'Card',
  Coupon = 'Coupon',
  AppleIAP = 'AppleIAP',
  GoogleIAP = 'GoogleIAP',
  Free = 'Free',
  Invite = 'Invite',
}

export enum PaymentProcessor {
  Apple = 'Apple',
  Google = 'Google',
  Mobilpay = 'Mobilpay',
  Questo = 'Questo',
  Gems = 'Gems',
}

export enum UserStatus {
  Registered = 'Registered',
  Review = 'Review',
  Accepted = 'Accepted',
  Active = 'Active',
  Rejected = 'Rejected',
  ConsiderationNow = 'ConsiderationNow',
  ConsiderationForLater = 'ConsiderationForLater',
  ApplicationReview = 'ApplicationReview',
}

export enum TokenTag {
  GetYourGuide = 'GetYourGuide',
  TripAdvisor = 'TripAdvisor',
  Booking = 'Booking',
  FreeGift = 'FreeGift',
  Web = 'Web',
  Test = 'Test',
  Expedia = 'Expedia',
  Musement = 'Musement',
  Ceetiz = 'Ceetiz',
  Klook = 'Klook',
  Eventbrite = 'Eventbrite',
  Ghost = 'Ghost',
  Destination = 'Destination',
  FreeToursByFoot = 'FreeToursByFoot',
  Stripe = 'Stripe',
}

export enum UserInviteRole {
  Administrator = 'Admin',
  Editor = 'Editor',
  User = 'User',
  Creator = 'Creator',
  BusinessCreator = 'BusinessCreator',
  Tester = 'Tester',
}

export enum ChallengeType {
  Standard = 'Standard',
  Reverse = 'Reverse',
  Mirror = 'Mirror',
  Flip = 'Flip',
}

export enum AssetType {
  Audio = 'audio',
  Image = 'image',
  Video = 'video',
}

export enum ChallengeAnswerType {
  Text = 'Text',
  TextSingleChoice = 'TextSingleChoice',
  TextMultiChoice = 'TextMultiChoice',
  ImageSingleChoice = 'ImageSingleChoice',
  ImageMultiChoice = 'ImageMultiChoice',
  ImageOrder = 'ImageOrder',
}

export enum CreatorType {
  Independent = 'Independent',
  Business = 'Business',
  TourOperator = 'TourOperator',
}

export enum QuestEventPricingType {
  SinglePrice = 'SinglePrice',
  MultiTier = 'MultiTier',
}

export enum QuestEventTierMode {
  Timeline = 'Timeline',
  LimitedSpots = 'LimitedSpots',
}

export enum QuestEventSectionType {
  Description = 'Description',
  Prizes = 'Prizes',
  PeopleGallery = 'PeopleGallery',
  CityGallery = 'CityGallery',
  Reviews = 'Reviews',
  HowTheEventWorks = 'HowTheEventWorks',
  SafetyNotice = 'SafetyNotice',
  HowTheAppWorks = 'HowTheAppWorks',
  AppDownload = 'AppDownload',
  Sharing = 'Sharing',
}

export enum QuestEventEmailType {
  Booking = 'Booking',
  BookingReminder = 'BookingReminder',
  InterestedReminder = 'InterestedReminder',
  InvitationForAnyGame = 'InvitationForAnyGame',
  InvitationForEventGame = 'InvitationForEventGame',
}

export enum QuestTagIcon {
  sun = 'sun',
  instagram = 'instagram',
  heart = 'heart',
  kid = 'kid',
  alert = 'alert',
}

export enum PointOfInterestStatus {
  Active = 'Active',
  Inactive = 'Inactive',
}

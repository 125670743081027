import { useEffect, useRef } from 'react';
import {
  useController,
  SubmitHandler,
  UseFormReturn,
  useFormContext,
  UseControllerProps,
} from 'react-hook-form';

export const userFormField = ({
  name,
  rules,
  defaultValue,
  parse,
  format,
  control,
}: UseControllerProps & {
  parse?: (value: any) => any;
  format?: (value: any) => any;
}) => {
  const form = useFormContext();
  const controller = useController({
    name,
    rules,
    defaultValue,
    control: control || form.control,
  });

  return {
    ...controller,
    field: {
      ...controller.field,
      value: format ? format(controller.field.value) : controller.field.value,
      onChange: parse
        ? (ev) => controller.field.onChange(parse(ev.target.value))
        : controller.field.onChange,
    },
  };
};

export const useAutosave = (
  form: UseFormReturn<any>,
  onSubmit: SubmitHandler<any>,
  delay = 1000,
) => {
  const timeout = useRef(null);
  const data = form.watch();
  const previousData = useRef(data);

  useEffect(() => {
    if (timeout.current) {
      clearTimeout(timeout.current);
    }

    timeout.current = setTimeout(async () => {
      if (JSON.stringify(previousData.current) !== JSON.stringify(data)) {
        await onSubmit(data);
        previousData.current = data;
      }
    }, delay);
  }, [data, form, onSubmit, delay]);

  return data;
};

import * as React from 'react';
import { Locale } from '@app/enums';
import { localeToFlag } from '@app/lib/helpers';
import { LOCALE_TO_LANGUAGE } from '@app/constants';
import { SelectField } from '../../form/fields/SelectField';
import { Box } from '@material-ui/core';

export const SelectLocaleField = (props) => (
  <SelectField
    renderOption={(option: Locale) => (
      <>
        {localeToFlag(option)}{' '}
        <Box component="span" sx={{ ml: 1 }}>
          {LOCALE_TO_LANGUAGE[option]}
        </Box>
      </>
    )}
    options={Object.keys(Locale)}
    {...props}
  />
);

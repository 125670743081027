import React, { ComponentProps, ReactNode } from 'react';
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  useTheme,
} from '@material-ui/core';
import { userFormField } from '../hooks';
import { FormFieldProps } from '../types';
import { isNil } from 'lodash';

export const SelectField = ({
  name,
  label,
  helperText,
  rules,
  multiple,
  defaultValue,
  control,
  options,
  fullWidth = true,
  renderOption,
  ...props
}: FormFieldProps &
  ComponentProps<typeof Select> & {
    options: any[];
    renderOption?: (option: string | number) => ReactNode;
  }) => {
  const theme = useTheme();
  const {
    field: { ref, value, onChange },
    fieldState: { invalid },
  } = userFormField({
    name,
    rules,
    defaultValue,
    control,
  });

  const required = !!rules?.required;

  return (
    <FormControl fullWidth={fullWidth} required={required} error={invalid} variant="standard">
      {label && <InputLabel required={required}>{label}</InputLabel>}
      <Select
        multiple={multiple}
        // value={!isEmpty(inputProps.value) ? inputProps.value : multiple ? [] : ''}
        value={!isNil(value) ? value : multiple ? [] : ''}
        onChange={onChange}
        error={invalid}
        ref={ref}
        autoWidth
        {...props}
      >
        {options.map((option) =>
          typeof option === 'string' || typeof option === 'number' ? (
            <MenuItem
              key={option}
              value={option}
              style={{
                fontWeight: (multiple ? (value || []).includes(option) : value === option)
                  ? theme.typography.fontWeightBold
                  : theme.typography.fontWeightRegular,
              }}
            >
              {renderOption ? renderOption(option) : option}
            </MenuItem>
          ) : (
            <MenuItem
              key={option.key || option.label || option.value}
              value={option.value}
              style={{
                fontWeight: (
                  multiple ? (value || []).includes(option.value) : value === option.value
                )
                  ? theme.typography.fontWeightBold
                  : theme.typography.fontWeightRegular,
              }}
            >
              {renderOption ? renderOption(option.label) : option.label}
            </MenuItem>
          ),
        )}
      </Select>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
};

import React from 'react';
import { UserRole } from '@app/enums';
import { useUser } from '@app/hooks/useUser';
import intersection from 'lodash/intersection';

export const Visible = ({
  toRole,
  unlessRole,
  toRoles = [],
  unlessRoles = [],
  children,
}: {
  toRole?: UserRole;
  unlessRole?: UserRole;
  toRoles?: UserRole[];
  unlessRoles?: UserRole[];
  children: JSX.Element | JSX.Element[];
}): JSX.Element | null => {
  const { user, isSuperAdmin } = useUser();

  if (!user) {
    return null;
  }

  if ((toRole || toRoles.length) && isSuperAdmin()) {
    return <>{children}</>;
  }

  if (toRole && !user.roles.includes(toRole)) {
    return null;
  }

  if (unlessRole && user.roles.includes(toRole)) {
    return null;
  }

  if (toRoles.length && !intersection(toRoles, user.roles || []).length) {
    return null;
  }

  if (unlessRoles.length && intersection(toRoles, user.roles || []).length) {
    return null;
  }

  return <>{children}</>;
};

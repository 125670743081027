import { Box } from '@material-ui/core';
import { Alert } from '@material-ui/core';
import { isEmpty, noop } from 'lodash';
import React, { ComponentProps, ReactNode, useState } from 'react';
import {
  DefaultValues,
  FormProvider,
  SubmitHandler,
  useForm,
  UseFormReturn,
} from 'react-hook-form';

export const Form = <FormData extends any>({
  children,
  onSubmit = noop,
  form,
  defaultValues,
  ...formProps
}: Omit<ComponentProps<typeof Box>, 'onSubmit'> & {
  children: ReactNode;
  onSubmit?: SubmitHandler<any>;
  defaultValues?: DefaultValues<any>;
  form?: UseFormReturn<FormData>;
  autoComplete?: 'off' | 'on';
}) => {
  const [globalError, setGlobalError] = useState(null);

  form =
    form ??
    useForm<FormData>({
      defaultValues,
    });

  return (
    <FormProvider {...form}>
      <Box
        component="form"
        noValidate
        onSubmit={form.handleSubmit(async (...args) => {
          try {
            await onSubmit(...args);
            setGlobalError(null);
          } catch (error) {
            if (
              Array.isArray(error.graphQLErrors) &&
              error.graphQLErrors[0]?.extensions?.exception?.response?.message
            ) {
              const { message } = error.graphQLErrors[0]?.extensions?.exception?.response;

              message.forEach((validationError) => {
                if (typeof validationError === 'string') {
                  form.setError(validationError.split(' ')[0] as any, {
                    type: 'manual',
                    message: validationError.substr(validationError.indexOf(' ') + 1),
                  });
                } else {
                  const { property, constraints } = validationError;
                  form.setError(property, {
                    type: 'manual',
                    message: Object.values(constraints).join(', '),
                  });
                }
              });
            } else {
              setGlobalError(error);
            }
          }
        })}
        {...formProps}
      >
        {!isEmpty(form.formState.errors) && (
          <Alert severity="error" sx={{ mb: 2 }}>
            Form has invalid fields!
          </Alert>
        )}
        {!!globalError && (
          <Alert severity="error" sx={{ mb: 2 }}>
            {globalError.message}
          </Alert>
        )}
        {children}
      </Box>
    </FormProvider>
  );
};

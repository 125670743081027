import mediaQuery from 'css-mediaquery';
import { createTheme, responsiveFontSizes } from '@material-ui/core/styles';

const ssrMatchMedia = (query) => ({
  matches: mediaQuery.match(query, {
    width: 800,
  }),
});

const theme = responsiveFontSizes(
  createTheme({
    palette: {
      primary: {
        main: '#325CFC',
      },
      secondary: {
        main: '#D432FC',
      },
      background: {
        default: '#fff',
      },
      action: {
        disabledOpacity: 0.6,
      },
    },
    typography: {
      fontFamily: '"Inter", "Poppins", "Helvetica", "Arial", sans-serif',
    },
    components: {
      MuiButtonBase: {
        defaultProps: {
          disableRipple: true,
        },
      },
      MuiUseMediaQuery: {
        defaultProps: {
          ssrMatchMedia,
        },
      },
      MuiGrid: {
        defaultProps: {
          spacing: 3,
        },
      },
    },
  }),
);

export default theme;
